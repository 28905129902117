import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Drawer, Button, Space} from "antd";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";
// import OrderContentDelete from "./OrderConformatuinPopUp";
import CommonComponent from "../Common Component/CommonComponent";
import "./OrderMainContnet.css";
import Top_navbar from "../DashBaord/Top_navbar";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import { list } from "../../controllers/orders/ordersControllers";
import { getPage } from "../../controllers/common";
import OrderFilterDrawer from "./OrderFilterDrawer";
import { orderStatus } from '../../controllers/common';

function OrderMainContent(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

OrderMainContent.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  }

 

  const handleDetailsShow = (item) => {
    console.log("item", item);
    navigate("/order/details", { state: item });
  };

  /********************** Integratin Stare Here **********************/
  const [isLoading, setIsLoading] = useState(true);
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");

  /*********************************************************
   *  This function is use to fetch order list
   *********************************************************/
  const getList = async () => {
    setListData([]);
    setIsLoading(true);
    const options = {
      type: "",
      condition: {
        paymentStatus: { '$nin': ['Initialize', 'Fail'] },
        ...(showRequest ? { status: showRequest } : null),
      },
      ...(filter ? { filter: filter } : null),
      select: {},
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    if (listData.status === true) {
      if (listData.result.length > 0) {
        setListData(listData?.result);
        setIsLoading(false);
      } else {
        // setListData([{ order_seq_id: "No Data Found" }]);
        setIsLoading(false);
      }
      const countOptions = {
        type: "count",
        condition: {
          paymentStatus: { '$nin': ['Initialize', 'Fail'] },
          ...(showRequest ? { status: showRequest } : null),
        },
        ...(filter ? { filter: filter } : null)
      };
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result));
      setIsLoading(false);
    } else {
      // setListData([{ order_seq_id: "No Data Found" }]);
      setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {

    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre) => ({
        ...pre,
        'filter_by': form.get('filter_by') || '',
        'search': form.get('search') || '',
        'to': form.get('to_date') || '',
        'from': form.get('from_date') || '',

      }))
      // console.log('filter',filter);
      // alert('submit');
      // setSkip(0);
      handleFiterDrawer();
    } catch (error) {

    }
  }

  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Frisbee Store || Orders List";
  }, [currentPage, showRequest, filter]);
  // filter Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const RequesttoggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_navbar title={`Order List`}></Top_navbar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value_rating_review">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => setShowRequest("")}
                    />
                    <Tab
                      label="Pending"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => setShowRequest("Pending")}
                    />
                    <Tab
                      label="Completed"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={() => setShowRequest("Complete")}
                    />
                    <Tab
                      label="On-Hold"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={() => setShowRequest("Hold")}
                    />
                  </Tabs>
                </div>

                <div className="filters_inventory_Rating_review">
                  <div
                    className="fa_filter_rating_review"
                    // onClick={RequesttoggleDrawer}
                    onClick={handleFiterDrawer}
                  >
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Filters
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <OrderMainContent
              // value={value}
              // index={0}
              className="main_container_payments"
            >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    {/* <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Recent Orders
                      </h6>
                    </div> */}
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Order Id</th>
                              <th>Products</th>
                              <th>User Details</th>
                              <th>Store Details</th>
                              <th>Amount</th>
                              <th>Status</th>
                              <th>Payment Status</th>
                              <th>Date</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index} onDoubleClick={()=>handleDetailsShow(items)}>
                                    <td>{items?.order_seq_id}</td>
                                    <td>{items?.orderdetails?.length} Item</td>
                                    <td>
                                      <span>{items?.userData?.name}</span>
                                      <br />
                                      <span>{items?.userData?.email}</span>
                                      <br />
                                      <span>{items?.userData?.phone}</span>
                                      <br />
                                    </td>
                                    <td>
                                      <span>{items?.shopId?.shop_name}</span>
                                      <br />
                                      <span>{items?.shopId?.email}</span>
                                      <br />
                                      <span>{items?.shopId?.phone}</span>
                                      <br />
                                    </td>
                                    <td>AED {items?.paidAmount?.toFixed(2)}</td>
                                    <td 
                                      dangerouslySetInnerHTML={{
                                        __html: orderStatus(items?.status),
                                      }}>
                                      {/* {items?.status} */}
                                    </td>
                                    <td>{items?.paymentStatus?.charAt(0).toUpperCase() + items?.paymentStatus?.slice(1).toLowerCase()}</td>
                                    <td>{moment(`${items?.createdAt}`).format("DD/MM/YYYY, hh:mm A")}</td>
                                    <td>
                                      <Dropdown
                                        overlay={
                                          <Menu>
                                            <Menu.Item
                                              key="1"
                                              onClick={() =>
                                                handleDetailsShow(items)
                                              }
                                            >
                                              <Link to={false}>
                                                <FaEye />
                                                <span className="show_span_edit">
                                                  View Details
                                                </span>
                                              </Link>
                                            </Menu.Item>
                                          </Menu>
                                        }
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? (
                              <SkeltonList row={10} col={8} />
                            ) : (
                              <tr>
                                <td colspan="9" className="text-center">
                                  <img
                                    src="https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg"
                                    width="50%"
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OrderMainContent>
          </Box>
        </div>
        {/* <OrderContentDelete popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> */}
        <OrderFilterDrawer
          drawerOpen={drawerOpen}
          RequesttoggleDrawer={RequesttoggleDrawer}
        />
      </div>
        {/* Filter */}
        <Drawer
        title="Order Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='order_seq_id' selected={filter?.filter_by === 'order_seq_id' ? true : false} >Order ID</option>
                  <option value='user_name' selected={filter?.filter_by === 'user_name' ? true : false}>User Name</option>
                  <option value='user_phone' selected={filter?.filter_by === 'user_phone' ? true : false}>User Phone</option>
                  <option value='user_email' selected={filter?.filter_by === 'user_email' ? true : false}>User Email</option>
                  <option value='status' selected={filter?.filter_by === 'status' ? true : false}>Status</option>
                  <option value='paymentStatus' selected={filter?.filter_by === 'paymentStatus' ? true : false}>Payment Status</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field_field"
                  id="search"
                  ref={inputRef2}
                // onChange={handleFilterChnage} 
                // value={filter?.search}             
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field_field"
                  id="from_date"
                  ref={inputRef3}
                // onChange={handleFilterChnage}          
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field_field"
                  id="to_date"
                  ref={inputRef4}
                // onChange={handleFilterChnage}             
                />
              </div>
              <br />
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-primary "
                  style={{ backgroundColor: "black", color: "white",border:"none" }}
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </> 
  );
}
